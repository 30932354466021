import {
  ComparativeDisplayType,
  FilterConditionFormatBody,
  FilterPatchBody,
  KpiOptionObject,
  RollingType
} from './GlobalKpiOption'
import { TagStatusSeverity, WidgetTagObject } from './GlobalWidgetTag'
import { CustomColors } from './GlobalCustomization'
import { AdditionalSegment } from './GlobalAdditionalSegment'

export interface AttributeOption {
  allow_custom_segment_by: boolean
  id: string
  attribute_id: string
  hierarchy_level: number
  name: string
  type: DataType
  disabled: boolean
  relation_key: string
  suggested: boolean
}

export enum BreakdownByDisplayType {
  CLOSE_BY = 'CLOSE_BY',
  STACKED = 'STACKED',
  STACKED_100_PERCENT = 'STACKED_100_PERCENT'
}

export enum ComparativePeriod {
  LAST_PERIOD = 'LAST_PERIOD',
  LAST_YEAR = 'LAST_YEAR',
  AVERAGE_LAST_180_DAYS = 'AVERAGE_LAST_180_DAYS'
}

export enum DateFilterType {
  PERIOD = 'PERIOD',
  POINT = 'POINT'
}

export enum DataType {
  BOOLEAN = 'BOOLEAN',
  BIGINT = 'BIGINT',
  DATE = 'DATE',
  DOUBLE = 'DOUBLE',
  STRING = 'STRING',
  TIMESTAMP = 'TIMESTAMP'
}

export enum ElementType {
  KPI = 'KPI',
  TAG = 'TAG'
}

export enum PeriodFilter {
  THIS_WEEK_TO_TODAY = 'THIS_WEEK_TO_TODAY',
  THIS_MONTH_TO_TODAY = 'THIS_MONTH_TO_TODAY',
  THIS_QUARTER_TO_TODAY = 'THIS_QUARTER_TO_TODAY',
  THIS_YEAR_TO_TODAY = 'THIS_YEAR_TO_TODAY',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_QUARTER = 'LAST_QUARTER',
  LAST_YEAR = 'LAST_YEAR',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
  LAST_365_DAYS = 'LAST_365_DAYS',
  LATEST_MONTH_WITH_DATA = 'LATEST_MONTH_WITH_DATA',
  LAST_3_MONTHS_TO_TODAY = 'LAST_3_MONTHS_TO_TODAY',
  LAST_6_MONTHS_TO_TODAY = 'LAST_6_MONTHS_TO_TODAY',
  LAST_12_MONTHS_TO_TODAY = 'LAST_12_MONTHS_TO_TODAY',
  THIS_YEAR_AND_COMING = 'THIS_YEAR_AND_COMING'
}

export enum ForecastPeriod {
  FILL_CURRENT_WEEK = 'FILL_CURRENT_WEEK',
  FILL_CURRENT_MONTH = 'FILL_CURRENT_MONTH',
  FILL_CURRENT_QUARTER = 'FILL_CURRENT_QUARTER',
  FILL_CURRENT_YEAR = 'FILL_CURRENT_YEAR',
  FILL_1_MONTH_AHEAD = 'FILL_1_MONTH_AHEAD',
  FILL_3_MONTH_AHEAD = 'FILL_3_MONTH_AHEAD',
  FILL_6_MONTH_AHEAD = 'FILL_6_MONTH_AHEAD',
  FILL_12_MONTH_AHEAD = 'FILL_12_MONTH_AHEAD'
}

export const translateForecastPeriod: Record<ForecastPeriod, string> = {
  [ForecastPeriod.FILL_CURRENT_WEEK]: 'Resten av veckan',
  [ForecastPeriod.FILL_CURRENT_MONTH]: 'Resten av månaden',
  [ForecastPeriod.FILL_CURRENT_QUARTER]: 'Resten av kvartalet',
  [ForecastPeriod.FILL_CURRENT_YEAR]: 'Resten av året',
  [ForecastPeriod.FILL_1_MONTH_AHEAD]: 'Till och med nästa månad',
  [ForecastPeriod.FILL_3_MONTH_AHEAD]: 'Till och med 3 månader framåt',
  [ForecastPeriod.FILL_6_MONTH_AHEAD]: 'Till och med 6 månader framåt',
  [ForecastPeriod.FILL_12_MONTH_AHEAD]: 'Till och med 12 månader framåt'
}

export enum VisualizationConstraint {
  SMALLEST = 'SMALLEST',
  LARGEST = 'LARGEST',
  KPI_VARIABLES = 'KPI_VARIABLES'
}

export enum FunnelStatus {
  COMPLETED = 'COMPLETED',
  TERMINATED = 'TERMINATED',
  UNSET = 'UNSET'
}

export enum DatasetType {
  ENUM = 'enum',
  NUMBER = 'number',
  STRING = 'string',
  NUMBER_TAG = 'tag_number',
  DATE = 'date'
}

export enum KeyFigureVisualizationType {
  FOCUS = 'FOCUS',
  LIST = 'LIST'
}

export const translateKeyFigureVisualizationType: Record<
  KeyFigureVisualizationType,
  string
> = {
  [KeyFigureVisualizationType.FOCUS]: 'Fokus',
  [KeyFigureVisualizationType.LIST]: 'Lista'
}

export type SegmentPeriod = {
  from_date: string
  to_date: string
}

interface SegmentPath {
  attribute_option_id: string | null
  label: string | number | null
  period: SegmentPeriod | null
  type: DataType
  technical_name: string
}

export interface WidgetDataLabel extends SegmentPath {
  path: SegmentPath[]
}

export interface ParsedSegmentPath extends SegmentPath {
  display_label: string
}

export interface ParsedWidgetDataLabel extends WidgetDataLabel {
  display_label: string
  path: ParsedSegmentPath[]
}

export type StatusData = TagStatusSeverity[] | null

export type Dataset = {
  breakdown_index: number
  kpi_option_id: string | null
  label: string
  breakdown_label: string | number | null
  data: (number | null | string[])[]
  percentages?: (number | null)[]
  statuses: StatusData[]
  tag_option_id: string | null
  type: DatasetType
  index: number
  is_comparative_data: boolean
  unit: string | null
}

export type TKPIValue = {
  value: number
  index: number
  kpi_option_id: string
  comparative_value?: number | null
}

export type WidgetData = {
  data: Dataset[]
  labels: WidgetDataLabel[]
  external_links: (string | null)[]
  coordinates: [number | null, number | null][]
  totals:
    | {
        index: number
        kpi_option_id: string
        label: string | null
        data: {
          value: number
          comparative_value: number | null
        }
      }[]
    | null
  values: TKPIValue[]
}

export type ListDatasetData = (number | null | string[] | number[])[]
export type DefaultDatasetData = (number | null)[]

export type TViewport = {
  latitude: number
  longitude: number
  pitch: number
  zoom: number
}

export type FormattedWidgetDataDataset = {
  id: string
  breakdownLabel: string | number | null
  comparativeDisplayTypeSelected: ComparativeDisplayType | null
  data: DefaultDatasetData | ListDatasetData
  hidden: boolean
  increaseIsPositive: boolean
  index: number
  isComparativeData: boolean
  kpiOptionId?: string
  label: string
  lowerBoundThreshold: number | null
  numberOfDecimals: number
  percentages: (number | null)[] | undefined
  prettyData: (string | number | string[] | number[] | null)[]
  settingIndex: number
  showComparativeValue: boolean
  statuses: StatusData[]
  total: string | number | null
  type: DatasetType
  unit: string | null
  upperBoundThreshold: number | null
}

export type FormattedWidgetData = {
  labels: ParsedWidgetDataLabel[]
  links: (string | null)[]
  datasets: FormattedWidgetDataDataset[]
  coordinates: [number | null, number | null][]
}

export enum WidgetType {
  BAR_CHART = 'BAR_CHART',
  BUBBLE_CHART = 'BUBBLE_CHART',
  COMBO_CHART = 'COMBO_CHART',
  COMMENT = 'COMMENT',
  FUNNEL = 'FUNNEL',
  IMAGE = 'IMAGE',
  KEY_FIGURE = 'KEY_FIGURE',
  LINE_CHART = 'LINE_CHART',
  LIST = 'LIST',
  MAP = 'MAP',
  PIE_CHART = 'PIE_CHART',
  TEXT = 'TEXT'
}

type FunnelStagePut = {
  title: string
  hidden: boolean
  status: FunnelStatus
}

export type FunnelStagePutBody = FunnelStagePut[]

export type FunnelStage = {
  title: string
  hidden: boolean
  status: FunnelStatus
  index: 0
}

export type TSortByOption = {
  label: string
  value: string | boolean
  key: 'sort_by_segment' | 'sort_by_kpi_option_id' | 'sort_by_widget_tag_id'
}

export type TSortByType =
  | DatasetType.STRING
  | DatasetType.DATE
  | DatasetType.NUMBER

interface BaseWidget {
  id: string
  dashboard_id: string
  fact_table_id: string
  title: string
  status: {
    // Encountered an error when fetching data.
    broken: boolean

    // One or more KPI:s are missing required targets.
    required_target_missing: boolean

    // All KPI:s has not been marked as modelled by implementaion lead.
    required_data_modelling_missing: boolean
  }
  layout: {
    width: number
    height: number
    x: number
    y: number
  }
  image: {
    url: string
    name: string
  } | null
  settings: {
    kpi_options: KpiOptionObject[]
    filters: FilterConditionFormatBody[]
    min_value: number | null
    max_value: number | null
    embeddable: boolean
    invert_y_axis: boolean
    label_chars: number | null
    show_date: boolean
    show_title: boolean
    show_subtitle: boolean
    limit_selection_to_primary_kpi: boolean
    key_figure_visualization_type: KeyFigureVisualizationType
    subtitle: string
    top_n_limit: number | null
    type: {
      selected: WidgetType
      options: { value: WidgetType; disabled: boolean }[]
    }
    tags: {
      selected: WidgetTagObject[]
      options: {
        id: string
        name: string
      }[]
    }
    segment_by: {
      allow_click: boolean
      selected: string | null
      type: DataType
      options: AttributeOption[]
      technical_name: string | null
      additional: AdditionalSegment[]
      display_name: string | null
      stages: FunnelStage[]
    }
    sort_by: {
      available: boolean
      allow_sort_on_kpi_variable: boolean
      options: TSortByOption[]
      visualization_constraint: VisualizationConstraint | null
      selected: boolean | string | null
      type: TSortByType
    }
    date_filter: {
      type: DateFilterType
      selected: {
        from_date: string | null
        to_date: string | null
        value: PeriodFilter
      }
      options: PeriodFilter[]
      rolling_active: RollingType | null
      restrict_to_period: { selected: boolean; available: boolean }
    }
    comparative_period: {
      available: boolean | null
      selected: {
        from_date: string
        to_date: string
        value: ComparativePeriod | null
      }
      options: ComparativePeriod[]
      increase_is_positive: boolean | null
    }
    external_link: {
      available: boolean
      show: boolean
    }
    allowed_filter_attributes: string[]
    date_filter_is_locked: boolean
    horizontal: boolean
    breakdown_by_display_type: BreakdownByDisplayType
    show_header: boolean
    show_vertical_dividers: boolean
    show_total: boolean
    forecast_period: {
      available: boolean
      selected: ForecastPeriod | null
      options: ForecastPeriod[]
    }
    viewport: TViewport
  }
  colors?: CustomColors
  updated_at: Date
  created_at: Date
  customer_id?: string // used with embedded widget
}

export interface WidgetObject extends BaseWidget {
  data: WidgetData | null
}

export interface GetOneWidget extends WidgetObject {
  data: WidgetData
}

export interface WidgetBody {
  [key: string]: WidgetObject
}

export interface WidgetPatchBody {
  data: InnerWidgetPatchBody
}

export interface InnerWidgetPatchBody {
  additional_kpi_templates?: string[]
  comparative_period?: ComparativePeriod | null
  date_filter?: PeriodFilter
  element_order?: {
    id: string
    type: ElementType
  }[]
  embeddable?: boolean
  filters?: FilterPatchBody[] | null
  forecast_period?: ForecastPeriod | null
  image?: null
  invert_y_axis?: boolean
  label_chars?: number | null
  max_value?: number | null
  min_value?: number | null
  segment_by?: string | null
  segment_group?: string
  show_date?: boolean
  show_title?: boolean
  title?: string
  top_n_limit?: number | null
  type?: WidgetType
  visualization_constraint?: VisualizationConstraint | null
  widget_tags?: { id: string; index: number }[]
  date_filter_is_locked?: boolean
  horizontal?: boolean
  breakdown_by_display_type?: BreakdownByDisplayType
  restrict_to_period?: boolean
  segment_by_display_name?: string | null
  show_header?: boolean
  show_vertical_dividers?: boolean
  show_external_link?: boolean
  show_total?: boolean
  longitude?: number
  latitude?: number
  pitch?: number
  zoom?: number
  show_subtitle?: boolean
  limit_selection_to_primary_kpi?: boolean
  key_figure_visualization_type?: KeyFigureVisualizationType
  subtitle?: string
}

export interface WidgetPostBody {
  data: {
    dashboard_id: string
    kpi_id: string | null
    perform_kpi_id?: string
    width?: number
    widget_id?: string
    height?: number
    type?: WidgetType.TEXT | WidgetType.COMMENT | WidgetType.IMAGE
    x?: number
    y?: number
  }
}

export type CustomPeriodFilter = {
  from: string | null
  to: string | null
}
