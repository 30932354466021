import { cloneDeep } from 'lodash'
import { DashboardFilter } from 'types/GlobalDashboardFilter'
import {
  AttributeOption,
  ComparativePeriod,
  CustomPeriodFilter,
  DateFilterType,
  ParsedSegmentPath,
  PeriodFilter,
  WidgetObject
} from 'types/GlobalWidget'
import { translateComparativePeriod } from 'utils/enumTranslator'

export const getSegmentSetters = (
  widget: WidgetObject,
  dashboardFilter: DashboardFilter,
  setDashboardFilter: (
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void,
  setDetailsDialogOpen: (
    open: boolean,
    segments: ParsedSegmentPath[],
    initialId: string | null
  ) => void,
  setCustomSegmentBy: (
    attributeOptionId: string,
    dbFilter: DashboardFilter
  ) => void,
  standaloneWidget: boolean,
  embedded: boolean,
  editMode: boolean
) => {
  const allowClick =
    widget.settings.segment_by.allow_click && !embedded && !editMode

  const allowFilter =
    widget.settings.segment_by.allow_click && !standaloneWidget && !editMode

  const allowDetails =
    allowClick &&
    widget.settings.kpi_options.some((option) => option.can_show_details) &&
    !editMode

  const allowExternalLink =
    allowClick &&
    widget.settings.external_link.available &&
    widget.settings.external_link.show &&
    !editMode

  return {
    onClickExternalLink: allowExternalLink
      ? (link: string | null) => (link ? window.open(link, '_blank') : {})
      : null,
    setCustomSegmentBy: allowClick
      ? (attributeOptionId: string, filters: ParsedSegmentPath[]) => {
          const newAttributeOptionIds = filters.map(
            (item) => item.attribute_option_id
          )

          // remove any affected filters
          const newDashboardFilter = cloneDeep(dashboardFilter).filter(
            (filter) =>
              filter.attribute_option_id &&
              !newAttributeOptionIds.includes(filter.attribute_option_id)
          )

          filters.forEach((filter) => {
            const selectedAttributeOption =
              widget.settings.segment_by.options.find(
                (opt) => opt.id === filter.attribute_option_id
              ) as AttributeOption

            newDashboardFilter.push({
              relation_key: selectedAttributeOption.relation_key,
              attribute_option_id: selectedAttributeOption.id,
              attribute: selectedAttributeOption.attribute_id,
              value: filter.label
            })
          })

          setCustomSegmentBy(attributeOptionId, newDashboardFilter)
        }
      : null,
    setDetailsDialog: allowDetails
      ? (filters: ParsedSegmentPath[]) =>
          setDetailsDialogOpen(true, filters, null)
      : null,
    setSegmentFilters: allowFilter
      ? (filters: ParsedSegmentPath[]) => {
          const newAttributeOptionIds = filters.map(
            (item) => item.attribute_option_id
          )

          // remove any affected filters
          const newDashboardFilter = cloneDeep(dashboardFilter).filter(
            (filter) =>
              filter.attribute_option_id &&
              !newAttributeOptionIds.includes(filter.attribute_option_id)
          )

          let customPeriodFilter: CustomPeriodFilter | undefined = undefined

          filters.forEach((filter) => {
            if (filter.period) {
              customPeriodFilter = {
                from: filter.period.from_date,
                to: filter.period.to_date
              }
            } else {
              const selectedAttributeOption =
                widget.settings.segment_by.options.find(
                  (opt) => opt.id === filter.attribute_option_id
                ) as AttributeOption

              newDashboardFilter.push({
                relation_key: selectedAttributeOption.relation_key,
                attribute_option_id: selectedAttributeOption.id,
                attribute: selectedAttributeOption.attribute_id,
                value: filter.label
              })
            }
          })

          setDashboardFilter(newDashboardFilter, customPeriodFilter)
        }
      : null
  }
}

export const getIsComparativeButIllegal = (
  dateFilter: PeriodFilter,
  comparativePeriod: ComparativePeriod | null,
  dateFilterType: DateFilterType
) => {
  if (comparativePeriod) {
    return !(
      comparativePeriod in
      translateComparativePeriod[dateFilterType][dateFilter]
    )
  }

  return false
}
