import React, { useMemo, useState } from 'react'
import { cloneDeep } from 'lodash'

import {
  InnerWidgetPatchBody,
  KeyFigureVisualizationType,
  translateKeyFigureVisualizationType,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import {
  translateBreakdownByDisplayType,
  translateWidgetType
} from 'utils/enumTranslator'

import Alert from 'components_new/atoms/Alert'
import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import Grid from 'components_new/atoms/Grid'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Switch from 'components_new/atoms/Switch'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SettingsToggleOption from 'components_new/molecules/SettingsToggleOption'
import TooltipToggleButtonGroup from 'components_new/molecules/TooltipToggleButtonGroup'

import { mapBetaTooltip } from 'components_new/organisms/Widget/types/Map/utils'

import {
  DEFAULT_TYPES,
  ORDER,
  breakdownByDisplayTypeOptions,
  hasBreakdown
} from './utils'

interface VisualizationSectionProps {
  updateWidget: (arg0: string, arg1: InnerWidgetPatchBody) => void
  widget: WidgetObject
}

const LEGAL_TYPES = [
  WidgetType.BAR_CHART,
  WidgetType.BUBBLE_CHART,
  WidgetType.COMBO_CHART,
  WidgetType.FUNNEL,
  WidgetType.KEY_FIGURE,
  WidgetType.LINE_CHART,
  WidgetType.LIST,
  WidgetType.MAP,
  WidgetType.PIE_CHART
]

const VisualizationSection = (props: VisualizationSectionProps) => {
  const { updateWidget, widget } = props

  if (!LEGAL_TYPES.includes(widget.settings.type.selected)) {
    return null
  }

  /*-- toggle --*/
  const handleToggle = (
    property:
      | 'horizontal'
      | 'show_header'
      | 'show_total'
      | 'show_vertical_dividers'
  ) => {
    let body: InnerWidgetPatchBody = {}

    switch (property) {
    case 'horizontal':
      body = {
        horizontal: !widget.settings.horizontal
      }
      break
    case 'show_header':
      body = {
        show_header: !widget.settings.show_header
      }
      break
    case 'show_total':
      body = {
        show_total: !widget.settings.show_total
      }
      break
    case 'show_vertical_dividers':
      body = {
        show_vertical_dividers: !widget.settings.show_vertical_dividers
      }
      break
    }

    updateWidget(widget.id, body)
  }

  const [showMore, setShowMore] = useState<boolean>(
    !DEFAULT_TYPES.includes(widget.settings.type.selected)
  )

  const visualizationOptions = useMemo(() => {
    const options = cloneDeep(widget.settings.type.options)

    if (showMore) {
      return options.sort(
        (a, b) => ORDER.indexOf(a.value) - ORDER.indexOf(b.value)
      )
    } else {
      return options
        .filter((option) => DEFAULT_TYPES.includes(option.value))
        .sort((a, b) => ORDER.indexOf(a.value) - ORDER.indexOf(b.value))
    }
  }, [showMore])

  return (
    <SettingsGroup
      title="Visualisering"
      helperText={
        widget.settings.type.selected === WidgetType.MAP ? (
          <Alert
            icon={<Chip color="white" label="Beta" variant="outlined" />}
            severity="info"
            variant="filled"
            sx={{ mt: 2 }}
          >
            {mapBetaTooltip}
          </Alert>
        ) : undefined
      }
    >
      <SettingsItem>
        <Grid container={true} columns={5}>
          {visualizationOptions.map(({ value, disabled }) => {
            const selected = value === widget.settings.type.selected

            return (
              <Grid item={true} key={value} xs={1}>
                <SettingsToggleOption
                  disabled={disabled}
                  badge={value === WidgetType.MAP}
                  iconName={translateWidgetType[value].iconName}
                  onClick={() => updateWidget(widget.id, { type: value })}
                  selected={selected}
                  title={translateWidgetType[value].title}
                  tooltip={
                    disabled || value === WidgetType.MAP ? (
                      <>
                        {disabled
                          ? translateWidgetType[value].disabledTitle
                          : null}
                        {value === WidgetType.MAP ? (
                          <>
                            <Chip
                              color="info"
                              label="Beta"
                              size="small"
                              sx={{ my: 0.5 }}
                            />
                            {mapBetaTooltip}
                          </>
                        ) : null}
                      </>
                    ) : undefined
                  }
                />
              </Grid>
            )
          })}
        </Grid>
      </SettingsItem>
      <SettingsItem
        color="info"
        onClick={() => setShowMore(!showMore)}
        title={showMore ? 'Visa mindre' : 'Visa mer'}
        variant="action"
      />

      {/*-- horizontal/vertical --*/}
      {[WidgetType.BAR_CHART].includes(widget.settings.type.selected) ? (
        <SettingsItem
          actions={
            <Switch
              checked={widget.settings.horizontal}
              onChange={() => handleToggle('horizontal')}
              size="small"
            />
          }
          title="Visa horisontellt"
        />
      ) : null}

      {/*-- breakdown --*/}
      {[WidgetType.BAR_CHART, WidgetType.COMBO_CHART].includes(
        widget.settings.type.selected
      ) && hasBreakdown(widget) ? (
            <SettingsItem
              actions={
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {breakdownByDisplayTypeOptions.map((option) => {
                    const selected =
                  widget.settings.breakdown_by_display_type === option

                    return (
                      <Tooltip
                        key={option}
                        title={translateBreakdownByDisplayType[option].title}
                      >
                        <IconButton
                          color={selected ? 'primary' : undefined}
                          onClick={() =>
                            updateWidget(widget.id, {
                              breakdown_by_display_type: option
                            })
                          }
                          size="small"
                          sx={{
                            borderRadius: 1,
                            border: selected ? '1px solid black' : undefined
                          }}
                        >
                          <Icon
                            fontSize="small"
                            // eslint-disable-next-line max-len
                            name={translateBreakdownByDisplayType[option].iconName}
                          />
                        </IconButton>
                      </Tooltip>
                    )
                  })}
                </Box>
              }
              title="Uppdelning"
            />
          ) : null}

      {/*-- show header/lines/total --*/}
      {[WidgetType.LIST].includes(widget.settings.type.selected) ? (
        <>
          <SettingsItem
            actions={
              <Switch
                checked={widget.settings.show_header}
                onChange={() => handleToggle('show_header')}
                size="small"
              />
            }
            title="Visa rubriker"
          />
          <SettingsItem
            actions={
              <Switch
                checked={widget.settings.show_total}
                onChange={() => handleToggle('show_total')}
                size="small"
              />
            }
            title="Visa total"
          />
          <SettingsItem
            actions={
              <Switch
                checked={widget.settings.show_vertical_dividers}
                onChange={() => handleToggle('show_vertical_dividers')}
                size="small"
              />
            }
            title="Visa vertikala linjer"
          />
        </>
      ) : null}

      {/*-- variant of key figure --*/}
      {[WidgetType.KEY_FIGURE].includes(widget.settings.type.selected) ? (
        <SettingsItem
          actions={
            <TooltipToggleButtonGroup
              items={Object.values(KeyFigureVisualizationType).map((type) => {
                return {
                  title: (
                    <Text variant={'body2'}>
                      {translateKeyFigureVisualizationType[type]}
                    </Text>
                  ),
                  value: type
                }
              })}
              value={widget.settings.key_figure_visualization_type}
              fullWidth={false}
              size={'small'}
              onChange={(e, value) => {
                if (value) {
                  updateWidget(widget.id, {
                    key_figure_visualization_type:
                      value as KeyFigureVisualizationType
                  })
                }
              }}
            />
          }
          title="Variant"
        />
      ) : null}
    </SettingsGroup>
  )
}

export default VisualizationSection
