import React, {
  HTMLAttributes,
  JSXElementConstructor,
  ReactNode,
  SyntheticEvent
} from 'react'
import {
  Autocomplete as MUIAutocomplete,
  AutocompleteRenderOptionState,
  AutocompleteRenderGetTagProps
} from '@mui/material'
import ListboxComponent from './ListBoxComponent'

interface RenderInputParams {
  InputProps: any
  InputLabelProps: any
  disabled: boolean
}

interface AutocompleteProps {
  Autocomplete?: boolean
  autoHighlight?: boolean
  autoSelect?: boolean
  blurOnSelect?: boolean
  ChipProps?: object
  clearIcon?: ReactNode
  clearOnBlur?: boolean
  clearOnEscape?: boolean
  clearText?: string
  closeText?: string
  defaultValue?: any
  disabled?: boolean
  disableClearable?: boolean
  disableCloseOnSelect?: boolean
  filterSelectedOptions?: boolean
  forcePopupIcon?: 'auto' | boolean
  freeSolo?: boolean
  // eslint-disable-next-line no-unused-vars
  getOptionLabel?: (option: any) => string
  groupBy?: (option: any) => string
  helperText?: ReactNode
  id?: string
  inputValue?: string
  // eslint-disable-next-line no-unused-vars
  isOptionEqualToValue?: (option: any, value: any) => boolean
  limitTags?: number
  ListboxProps?: object
  loading?: boolean
  loadingText?: ReactNode
  multiple?: boolean
  noOptionsText?: ReactNode
  onChange?: (event: SyntheticEvent, value: any) => void
  onClose?: () => void
  onInputChange?: () => void
  onOpen?: () => void
  openText?: string
  openOnFocus?: boolean
  options: any[]
  PaperComponent?: JSXElementConstructor<HTMLAttributes<HTMLElement>>
  popupIcon?: ReactNode
  readOnly?: boolean
  // eslint-disable-next-line no-unused-vars
  renderInput: (params: RenderInputParams) => ReactNode
  // eslint-disable-next-line no-unused-vars
  renderOption?: (
    props: object,
    option: any,
    state: AutocompleteRenderOptionState
  ) => ReactNode
  renderTags?: (
    value: any[],
    getTagProps: AutocompleteRenderGetTagProps,
    ownerState: object
  ) => React.ReactNode
  size?: 'small' | 'medium'
  sx?: object
  value?: any
}

/**
 * The Autocomplete component let users enter and
 * edit text.
 *
 * It acts as a wrapper component for a
 * complete form control including a label,
 * input, and help text.
 */
const Autocomplete = React.forwardRef((props: AutocompleteProps, ref: any) => {
  return (
    <>
      <MUIAutocomplete
        data-openreplay-obscured
        {...props}
        ref={ref}
        groupBy={
          props.options && props.options.length > 0 && props.options[0].group
            ? (option) => option.group
            : undefined
        }
        componentsProps={{
          popper: { sx: { zIndex: 1600 } }
        }}
        renderGroup={(params) => params as any}
        disableListWrap
        ListboxComponent={
          ListboxComponent as React.ComponentType<
            React.HTMLAttributes<HTMLElement>
          >
        }
      />
    </>
  )
})

Autocomplete.defaultProps = {
  noOptionsText: 'Hittade inga alternativ'
}

Autocomplete.displayName = 'Autocomplete'
export default Autocomplete
