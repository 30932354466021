import {
  AttributeOption,
  DatasetType,
  TSortByType,
  VisualizationConstraint
} from 'types/GlobalWidget'

export const getSegmentByOptions = (options: AttributeOption[]) => {
  return options.map((option) => ({
    label: option.name,
    value: option.id
  }))
}

export const getSortOrderOptions = (
  sortByType: TSortByType,
  allowSortOnKpiVariable: boolean
) => {
  let options: { label: string; value: VisualizationConstraint }[] = []

  switch (sortByType) {
  case DatasetType.DATE:
    options = [
      {
        label: 'Tidigast först',
        value: VisualizationConstraint.SMALLEST
      },
      {
        label: 'Senast först',
        value: VisualizationConstraint.LARGEST
      }
    ]
    break
  case DatasetType.NUMBER:
    options = [
      {
        label: 'Störst värde först',
        value: VisualizationConstraint.LARGEST
      },
      {
        label: 'Minst värde först',
        value: VisualizationConstraint.SMALLEST
      }
    ]
    break
  default:
    options = [
      {
        label: 'Stigande (A till Ö)',
        value: VisualizationConstraint.SMALLEST
      },
      {
        label: 'Fallande (Ö till A)',
        value: VisualizationConstraint.LARGEST
      }
    ]

    break
  }

  if (allowSortOnKpiVariable) {
    options.push({
      label: 'Enligt kontonivåer',
      value: VisualizationConstraint.KPI_VARIABLES
    })
  }

  return options
}
