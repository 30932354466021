import React, { useState } from 'react'

import { useTheme } from '@mui/material/styles'

import 'chart.js/auto'
import { Chart } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import Box from 'components_new/atoms/Box'

import { getData, getOptions } from './utils'

import { translateWidgetType } from 'utils/enumTranslator'

import NoValue from '../../errors/NoValue'
import SegmentMenu from '../../SegmentMenu'

import {
  FormattedWidgetData,
  ParsedSegmentPath,
  ParsedWidgetDataLabel,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

interface PieChartProps {
  customSegmentBy: string | null
  formattedData: FormattedWidgetData
  scaleFactor: number
  setCustomSegmentBy:
    | ((attributeOptionId: string, segment: ParsedSegmentPath[]) => void)
    | null
  setDetailsDialog: ((segment: ParsedSegmentPath[]) => void) | null
  setSegmentFilters: ((segment: ParsedSegmentPath[]) => void) | null
  widget: WidgetObject
}

const PieChart = (props: PieChartProps) => {
  const {
    customSegmentBy,
    formattedData,
    scaleFactor,
    setCustomSegmentBy,
    setDetailsDialog,
    setSegmentFilters,
    widget
  } = props

  const [anchorPosition, setAnchorPosition] = useState<{
    top: number
    left: number
  } | null>(null)
  const [selectedSegment, setSelectedSegment] =
    useState<ParsedWidgetDataLabel | null>(null)
  const allowOnClick = !!setSegmentFilters || !!setDetailsDialog

  const theme = useTheme()

  if (formattedData.datasets.length === 0) {
    return (
      <NoValue
        iconName={translateWidgetType[WidgetType.BAR_CHART].iconName}
        scaleFactor={scaleFactor}
      />
    )
  }

  const handleClick = (
    event: PointerEvent,
    dataLabel: ParsedWidgetDataLabel
  ) => {
    event.stopPropagation()

    setAnchorPosition({ left: event.clientX, top: event.clientY })
    setSelectedSegment(dataLabel)
  }

  const handleClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation()

    setAnchorPosition(null)
    setSelectedSegment(null)
  }

  const data = getData(theme, formattedData)

  return (
    <>
      <Box sx={{ position: 'relative', flexGrow: 1, minHeight: 0 }}>
        <Chart
          type="pie"
          data={data}
          options={getOptions(
            allowOnClick,
            data,
            handleClick,
            theme,
            scaleFactor
          )}
          plugins={[ChartDataLabels as any]}
        />
      </Box>
      {allowOnClick && selectedSegment && (
        <SegmentMenu
          anchorPosition={anchorPosition}
          customSegmentBy={customSegmentBy}
          onClose={handleClose}
          segments={[selectedSegment]}
          setCustomSegmentBy={setCustomSegmentBy}
          setDetailsDialog={setDetailsDialog}
          setFilters={setSegmentFilters}
          widget={widget}
        />
      )}
    </>
  )
}

export default PieChart
