import { TNavigationItem } from 'components_new/molecules/NavigationItem'
import { AuthenticationState } from 'types/GlobalAuthentication'

export const getNavigationItems = (
  AuthStore: AuthenticationState
): TNavigationItem[] => {
  return [
    {
      icon: 'PaletteOutlined',
      href: '/admin/settings/customization',
      title: 'Anpassningar'
    },
    {
      icon: 'GroupOutlined',
      href: '/admin/settings/users',
      title: 'Användare'
    },
    {
      icon: 'VerifiedUserOutlined',
      href: '/admin/settings/authentication',
      title: 'Autentisering'
    },
    {
      icon: 'ApartmentOutlined',
      href: '/admin/settings/companies',
      title: 'Bolag'
    },
    {
      icon: 'AccountTreeOutlined',
      href: '/admin/settings/chart-of-accounts',
      title: 'Kontoplaner'
    },
    {
      icon: 'VpnKeyOutlined',
      href: '/admin/settings/allocation-keys',
      title: 'Fördelningsnycklar',
      hidden: Boolean(AuthStore.customer?.is_management_company)
    },
    {
      icon: 'PaidOutlined',
      href: '/admin/settings/economy',
      title: 'Ekonomiska nyckeltal'
    },
    {
      icon: 'TuneOutlined',
      href: '/admin/settings/config',
      title: 'Konfigurering'
    },
    {
      icon: 'LocalPoliceOutlined',
      href: '/admin/settings/license',
      title: 'Licens'
    },
    {
      icon: 'TitleOutlined',
      href: '/admin/settings/terminologi',
      title: 'Terminologi'
    },
    {
      icon: 'KeyOutlined',
      href: '/admin/settings/api-keys',
      title: 'API-nycklar',
      hidden: !AuthStore.customer?.is_management_company
    }
  ]
}
