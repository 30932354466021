import { Theme } from '@mui/material'
import Dayjs from 'dayjs'

import { UnitPrefix } from 'types/GlobalKpiOption'
import {
  WidgetObject,
  DataType,
  ParsedWidgetDataLabel
} from 'types/GlobalWidget'
import { hexToRgbString } from 'themes'
import {
  Chart,
  ChartEvent,
  ChartType,
  FontSpec,
  LegendElement,
  LegendItem,
  ScriptableAndScriptableOptions,
  ScriptableChartContext
} from 'chart.js'

export type Value = number | null

export type SegmentData = {
  unit: string | null
  unit_prefix: {
    label: string | null
    value: UnitPrefix | null
  }
  label: string
  data: Value[]
  number_of_decimals: number
  percentages?: Value[]
}

/**
 * @returns the custom legend list for a bar chart.
 */

const getOrCreateLegendList = (id: string) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer?.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.flexWrap = 'wrap'
    listContainer.style.overflow = 'hidden'
    listContainer.style.margin = '0'
    listContainer.style.padding = '0'

    legendContainer?.appendChild(listContainer)
  }

  return listContainer
}

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart: any, args: any, options: any) {
    const ul = getOrCreateLegendList(options.containerID)
    const scaleFactor = options.scaleFactor

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove()
    }

    // Reuse the built-in legendItems generator
    const items = chart?.options?.plugins?.legend?.labels?.generateLabels(chart)

    items?.forEach((item: any) => {
      let neutralizeColor

      if (
        options.titleToNeutralize &&
        options.titleToNeutralize === item.text
      ) {
        neutralizeColor = 'rgb(100, 116, 139)'
      }
      const li = document.createElement('li')

      li.style.alignItems = 'center'
      li.style.cursor = 'pointer'
      li.style.display = 'flex'
      li.style.flexDirection = 'row'
      li.style.marginLeft = `${8 * (1.5 * scaleFactor)}px`

      li.onclick = () => {
        chart.setDatasetVisibility(
          item.datasetIndex,
          !chart.isDatasetVisible(item.datasetIndex)
        )
        chart.update()
      }

      const boxSpan = document.createElement('span')

      boxSpan.style.background = neutralizeColor || item.fillStyle
      // Intentionally left here as comment:
      // boxSpan.style.borderStyle = 'solid'
      // boxSpan.style.borderWidth = `${2 * (1.5 * scaleFactor)}px`
      // boxSpan.style.borderColor = neutralizeColor || item.strokeStyle
      boxSpan.style.display = 'inline-block'
      boxSpan.style.height = `${8 * (1.5 * scaleFactor)}px`
      boxSpan.style.marginRight = `${4 * (1.5 * scaleFactor)}px`
      boxSpan.style.width = `${8 * (1.5 * scaleFactor)}px`

      const textContainer = document.createElement('p')

      textContainer.style.color = 'inherit'
      textContainer.style.fontSize = `${12 * scaleFactor}px`
      textContainer.style.margin = '0'
      textContainer.style.padding = '0'
      textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

      const text = document.createTextNode(item.text)

      textContainer.appendChild(text)

      li.appendChild(boxSpan)
      li.appendChild(textContainer)
      ul.appendChild(li)
    })
  }
}

/**
 * @returns The color above the line.
 */
const getUpperBoundColor = (
  theme: Theme,
  colorType: 'transparent' | 'main'
) => {
  return theme.palette.success[colorType]
}

/**
 * @returns The color below the line.
 */
const getLowerBoundColor = (
  theme: Theme,
  colorType: 'transparent' | 'main'
) => {
  return theme.palette.error[colorType]
}

/**
 * @returns The color for a bar based on thresholds.
 */
const getThresholdColor = (
  value: number,
  theme: Theme,
  increaseIsPositive: boolean,
  upperThresholdValue: number | null,
  lowerThresholdValue: number | null
) => {
  if (upperThresholdValue === null) {
    return null
  }

  const goalSucceeded = increaseIsPositive
    ? value >= upperThresholdValue
    : value <= upperThresholdValue

  if (goalSucceeded) {
    return theme.palette.success.main
  }

  const goalWarning =
    lowerThresholdValue !== null &&
    (increaseIsPositive
      ? value > lowerThresholdValue
      : value < lowerThresholdValue)

  if (goalWarning) {
    return theme.palette.warning.main
  }

  return theme.palette.error.main
}

/**
 * @returns The lines for thresholds.
 */
export const getThresholdLines = (theme: Theme, widget: WidgetObject) => {
  const lines: { value: number; color: string }[] = []

  widget.settings.kpi_options.map((option) => {
    const lowerThresholdValue = option.lower_bound_threshold
    const upperThresholdValue = option.upper_bound_threshold

    if (upperThresholdValue !== null) {
      lines.push({
        value: upperThresholdValue,
        color: getUpperBoundColor(theme, 'main') as string
      })
    }

    if (lowerThresholdValue !== null) {
      lines.push({
        value: lowerThresholdValue,
        color: getLowerBoundColor(theme, 'main') as string
      })
    }
  })

  return { lines }
}

export const horizontalLinePlugin = {
  id: 'horizontalLines',
  beforeDraw(chart: any, args: any, options: any) {
    options.lines?.forEach((line: { value: number; color: string }) => {
      const {
        ctx,
        chartArea: { left, right, bottom },
        scales: { y }
      } = chart

      const yPixelValue = y?.getPixelForValue(line.value)

      if (yPixelValue <= bottom) {
        ctx.save()
        ctx.strokeStyle = line.color
        ctx.lineWidth = 3
        ctx.setLineDash([5, 3]) /*dashes are 5px and spaces are 3px*/
        ctx.beginPath()
        ctx.moveTo(left, yPixelValue)
        ctx.lineTo(right, yPixelValue)
        ctx.stroke()
        ctx.restore()
      }
    })
  }
}

export const verticalLinePlugin = {
  id: 'verticalLines',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeDraw(chart: any, args: any, options: any) {
    options.lines?.forEach((line: { value: string; color: string }) => {
      const {
        ctx,
        chartArea: { bottom, top },
        scales: { x }
      } = chart

      ctx.save()
      ctx.strokeStyle = line.color
      ctx.setLineDash([5, 3]) /*dashes are 5px and spaces are 3px*/
      ctx.beginPath()
      ctx.moveTo(x?.getPixelForValue(line.value), bottom)
      ctx.lineTo(x?.getPixelForValue(line.value), top)
      ctx.stroke()
      ctx.restore()
    })
  }
}

export const statusAreaPlugin = {
  id: 'statusAreas',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  beforeDraw(chart: any, args: any, options: any) {
    const {
      ctx,
      chartArea: { right, bottom, left, top },
      scales: { x, y }
    } = chart

    const xLine = options.xLine.value
    const xPos = options.xLine.increaseIsPositive

    const yLine = options.yLine.value
    const yPos = options.yLine.increaseIsPositive

    const xLineLeftOfChart = xLine < x.min
    const xLineRightOfChart = xLine > x.max
    const xLineWithin = !xLineLeftOfChart && !xLineRightOfChart
    const yLineBelowChart = yLine < y.min
    const yLineAboveChart = yLine > y.max
    const yLineWithin = !yLineBelowChart && !yLineAboveChart
    const anyWithin = xLineWithin || yLineWithin

    const theme = options.theme

    ctx.save()

    const success = theme.palette.success.transparent
    const warning = theme.palette.warning.transparent
    const error = theme.palette.error.transparent

    const xLeft = x.getPixelForValue(
      xLineWithin ? xLine : xLineRightOfChart ? x.max : x.min
    )
    const yTop = y.getPixelForValue(
      yLineWithin ? yLine : yLineBelowChart ? y.min : y.max
    )

    if (xLine && yLine) {
      // Top Left
      if (anyWithin || (yLineBelowChart && xLineRightOfChart)) {
        ctx.fillStyle =
          yPos && !xPos
            ? success
            : (yPos && xPos) || (!yPos && !xPos)
                ? warning
                : error
        ctx.fillRect(left, top, xLeft - left, yTop - top)
      }

      // Top Right
      if (anyWithin || (yLineBelowChart && xLineLeftOfChart)) {
        ctx.fillStyle =
          yPos && xPos
            ? success
            : (!yPos && xPos) || (yPos && !xPos)
                ? warning
                : error
        ctx.fillRect(xLeft, top, right - xLeft, yTop - top)
      }

      // Bottom Right
      if (anyWithin || (yLineAboveChart && xLineLeftOfChart)) {
        ctx.fillStyle =
          !yPos && xPos
            ? success
            : (yPos && xPos) || (!yPos && !xPos)
                ? warning
                : error
        ctx.fillRect(xLeft, yTop, right - xLeft, bottom - yTop)
      }

      // Bottom Left
      if (anyWithin || (yLineAboveChart && xLineRightOfChart)) {
        ctx.fillStyle =
          !yPos && !xPos
            ? success
            : (!yPos && xPos) || (yPos && !xPos)
                ? warning
                : error
        ctx.fillRect(left, yTop, xLeft - left, bottom - yTop)
      }
    }

    if (xLine && !yLine) {
      // Left
      if (xLineWithin || xLineRightOfChart) {
        ctx.fillStyle = !xPos ? success : error
        ctx.fillRect(left, top, xLeft - left, bottom - top)
      }

      // Right
      if (xLineWithin || xLineLeftOfChart) {
        ctx.fillStyle = xPos ? success : error
        ctx.fillRect(xLeft, top, right - xLeft, bottom - top)
      }
    }

    if (!xLine && yLine) {
      // Top
      if (yLineBelowChart || yLineWithin) {
        ctx.fillStyle = yPos ? success : error
        ctx.fillRect(left, top, right - left, yTop - top)
      }

      // Bottom
      if (yLineAboveChart || yLineWithin) {
        ctx.fillStyle = !yPos ? success : error
        ctx.fillRect(left, yTop, right - left, bottom - yTop)
      }
    }
  }
}

export const formatValue = (
  value: number | null,
  unitPrefix: UnitPrefix | null,
  numberOfDecimals: number,
  toLocalString = true,
  equivalentFactor?: number | null
) => {
  const prefixValue: number | null = getPrefixValue(
    value,
    unitPrefix,
    equivalentFactor
  )

  if (prefixValue !== null) {
    if (toLocalString) {
      return prefixValue.toLocaleString('sv-SE', {
        maximumFractionDigits: numberOfDecimals
      })
    }

    return parseFloat(prefixValue.toFixed(numberOfDecimals))
  }

  return null
}

export const getPrefixValue = (
  value: number | null,
  unitPrefix: UnitPrefix | null,
  equivalentFactor?: number | null
) => {
  if (value === null) {
    return null
  }

  let divideBy = 1

  switch (unitPrefix) {
  case UnitPrefix.THOUSAND:
    divideBy = 1000
    break
  case UnitPrefix.MILLION:
    divideBy = 1000000
    break
  case UnitPrefix.BILLION:
    divideBy = 1000000000
    break
  default:
    break
  }

  const newValue = value / divideBy

  if (equivalentFactor) {
    return newValue * equivalentFactor
  } else {
    return newValue
  }
}

const PERCENTAGE_INFINITE_LIMIT = 1_000_000

export const formatPercentageDiffValue = (value: number) => {
  if (value > PERCENTAGE_INFINITE_LIMIT) {
    return '∞'
  }

  return value.toLocaleString('sv-SE', { maximumFractionDigits: 1 })
}

export const filterTopN = (topNLimit: number | null, data: any[]): any[] => {
  if (topNLimit) {
    return data.slice(0, topNLimit)
  }

  return data
}

export const getUnitLabel = (
  unit: string | null,
  unitPrefix: {
    selected: UnitPrefix | null
    options: { value: UnitPrefix; label: string }[]
  },
  equivalentUnit?: string | null
): string => {
  if (unit === '%') {
    return unit
  }

  if (equivalentUnit || equivalentUnit === '') {
    return equivalentUnit
  }

  let parsedUnit = ''

  if (unitPrefix.selected) {
    parsedUnit =
      unitPrefix.options.find((item) => item.value === unitPrefix.selected)
        ?.label || ''
  }

  if (unit) {
    parsedUnit = parsedUnit + unit
  }

  return parsedUnit
}

export const getTooltipLabel = (item: any, hasBreakdown: boolean) => {
  const dataIndex: number = item.dataIndex
  const unit: string = item.dataset.unit ? ` ${item.dataset.unit}` : ''
  const prettyData: string = item.dataset.prettyData[dataIndex]
  const dataset = hasBreakdown ? `${item.dataset.label + ': '}` : ''

  return `${dataset}${prettyData}${unit}`
}

export const getColor = (
  value: number | null,
  index: number,
  theme: Theme,
  increaseIsPositive: boolean,
  upperThresholdValue: number | null,
  lowerThresholdValue: number | null,
  alpha = 1,
  ignoreThreshold = false
) => {
  let thresholdColor = null

  if (value && !ignoreThreshold) {
    thresholdColor = getThresholdColor(
      value,
      theme,
      increaseIsPositive,
      upperThresholdValue,
      lowerThresholdValue
    )
  }

  if (thresholdColor) {
    return hexToRgbString(thresholdColor, alpha)
  }

  const CHART_COLOURS: Record<number, string> = {
    0: theme.palette.primary.main,
    1: theme.palette.secondary.main,
    2: theme.palette.tertiary.main,
    3: theme.palette.quaternary.main,
    4: theme.palette.quintary.main,
    5: theme.palette.senary.main,
    6: theme.palette.septenary.main,
    7: theme.palette.octonary.main,
    8: theme.palette.nonary.main,
    9: theme.palette.denary.main
  }
  const chartColor = CHART_COLOURS[index % 10] as string

  return hexToRgbString(chartColor, alpha)
}

export const getColorIndexAndAlpha = (
  showComparativeValue: boolean,
  isComparativeData: boolean,
  datasetIndex: number,
  kpiIndex: number
) => {
  if (showComparativeValue) {
    // special case where we want to use the same color
    // when comparing with VALUE
    return { index: kpiIndex, alpha: isComparativeData ? 0.5 : 1 }
  }

  return { index: datasetIndex, alpha: 1 }
}

export const getDiffGlyph = (diff: number) => {
  if (diff > 0) {
    return '▲'
  }

  if (diff < 0) {
    return '▼'
  }

  return '▶'
}

export const getDiffLabel = (
  diff: number | null,
  isComparativeButIllegal: boolean
) => {
  if (isComparativeButIllegal) {
    return ''
  }

  if (diff !== null) {
    return (
      getDiffGlyph(diff) +
      ' ' +
      (diff >= 0 ? '+' : '') +
      formatPercentageDiffValue(diff) +
      '%'
    )
  }

  return '+∞%'
}

export const getDiffColor = (
  diff: number | null,
  increaseIsPositive: boolean,
  theme: Theme
) => {
  if (diff !== null) {
    if (diff === 0) {
      return theme.palette.warning.text
    }
    if (increaseIsPositive) {
      return diff >= 0 ? theme.palette.success.text : theme.palette.error.text
    } else {
      return diff >= 0 ? theme.palette.error.text : theme.palette.success.text
    }
  }

  return increaseIsPositive
    ? theme.palette.success.text
    : theme.palette.error.text
}

export const formatAxisValue = (
  value: number,
  numberOfDecimals: number | null
) => {
  if (numberOfDecimals !== null) {
    return value.toLocaleString('sv-SE', {
      maximumFractionDigits: numberOfDecimals
    })
  }

  return value.toLocaleString('sv-SE')
}

/**
 * Check if forecast_period is selected and data
 * is shown over time.
 */
export const forecastIsActive = (widget: WidgetObject) => {
  return (
    !!widget.settings.forecast_period.selected &&
    widget.settings.segment_by.type === DataType.DATE
  )
}

// YYYY-M covers "year & quarter"
const ALLOWED_FORMATS = ['YYYY-MM-DD', 'YYYY-MM', 'YYYY-M', 'YYYY']

/**
 *
 * @param dataLabels  - Segment labels
 * @param index  - Current segment index
 * @param forecastActive - If forecast_period is selected and data is over time.
 * @param isComparativeData - If data is comparative.
 */
export const isForecast = (
  dataLabels: ParsedWidgetDataLabel[],
  index: number,
  forecastActive: boolean,
  isComparativeData: boolean
) => {
  return (
    forecastActive &&
    dataLabels[index] &&
    dataLabels[index].type === DataType.DATE &&
    Dayjs(dataLabels[index].label, ALLOWED_FORMATS).isValid() &&
    new Date().getTime() <
      new Date(dataLabels[index].label as string).getTime() &&
    !isComparativeData
  )
}

const getArea = function (element: any, horizontal: boolean) {
  if (horizontal) {
    const half = element.height / 2

    return {
      top: element.y - half,
      bottom: element.y + half,
      right: Math.max(element.x, element.base),
      left: Math.min(element.x, element.base)
    }
  }
  const half = element.width / 2

  return {
    left: element.x - half,
    right: element.x + half,
    top: Math.min(element.y, element.base),
    bottom: Math.max(element.y, element.base)
  }
}

const draw = function (ctx: any, x: number, y: number, skipped: any) {
  if (!skipped) {
    ctx.lineTo(x, y)
  } else {
    ctx.moveTo(x, y)
  }
}

export const boxBorderDashPlugin = {
  id: 'boxBorderDash',
  afterDatasetDraw(chart: any, args: any) {
    const context = chart.getContext()
    const meta = args.meta
    const dataset = meta.controller.getDataset()

    if (!dataset) return
    const { boxBorderDash, boxBorderWidth } = dataset

    if (!boxBorderDash?.length || !boxBorderWidth) return

    meta.data.forEach((element: any, dataIndex: number) => {
      const fullContext = {
        ...context,
        dataset,
        dataIndex,
        raw: element['$context'].raw
      }

      const borderWidth =
        boxBorderWidth instanceof Function
          ? boxBorderWidth(fullContext, element)
          : boxBorderWidth

      if (borderWidth === 0) return

      const borderDash =
        boxBorderDash instanceof Function
          ? boxBorderDash(fullContext, element)
          : boxBorderDash

      if (!borderDash || borderDash?.length === 0) return

      const ctx = chart.ctx
      const horizontal = element.horizontal
      const skip = element.borderSkipped
      const { left, right, top, bottom } = getArea(element, horizontal)

      ctx.beginPath()
      ctx.lineWidth = borderWidth
      ctx.strokeStyle = element.options.borderColor
      ctx.setLineDash(borderDash)
      ctx.moveTo(left, top)
      draw(ctx, right, top, skip.top)
      draw(ctx, right, bottom, skip.right)
      draw(ctx, left, bottom, skip.bottom)
      draw(ctx, left, top, skip.left)
      ctx.stroke()
      ctx.save()
    })
  }
}

/*-- legend settings --*/

export const LEGEND_BOX_SIZE = 10
export const LEGEND_FONT_SIZE = 16

export const getLegendBoxSize = (scaleFactor: number) => {
  return LEGEND_BOX_SIZE * scaleFactor
}

export const getLegendFontSize = (scaleFactor: number) => {
  return LEGEND_FONT_SIZE * scaleFactor
}

export const getLegendStyles = (
  scaleFactor: number,
  theme: Theme,
  type: ChartType
): {
  align: 'start'
  labels: {
    boxWidth: number
    boxHeight: number
    boxPadding: number
    padding: number
    font: ScriptableAndScriptableOptions<
      Partial<FontSpec>,
      ScriptableChartContext
    >
    generateLabels: (chart: Chart) => LegendItem[]
  }
  onHover: (event: ChartEvent) => void
  onClick: any
  // 'any' due to pie chart that differs. Should be:
  // (
  //   event: ChartEvent,
  //   legendItem: LegendItem,
  //   legend: LegendElement<ChartType>
  // ) => void
} => {
  const boxSize = LEGEND_BOX_SIZE * scaleFactor
  const fontSize = LEGEND_FONT_SIZE * scaleFactor

  return {
    align: 'start',
    labels: {
      boxWidth: boxSize,
      boxHeight: boxSize,
      boxPadding: scaleFactor,
      padding: boxSize,
      font: {
        size() {
          return fontSize
        }
      },
      generateLabels: function (chart) {
        const datasets = chart.data.datasets

        // pie chart
        if (type === 'pie') {
          const pieChartLegendItems =
            Chart.overrides.doughnut.plugins.legend.labels.generateLabels
          const legendItems = pieChartLegendItems(chart)

          return legendItems.map((legendItem) => ({
            text: legendItem.text,
            fillStyle: !legendItem.hidden
              ? legendItem.fillStyle
              : theme.palette.action.disabled,
            strokeStyle: 'transparent',
            lineWidth: 0,
            borderRadius: boxSize / 2,
            fontColor: !legendItem.hidden
              ? theme.palette.text.secondary
              : theme.palette.text.disabled
          }))
        } else {
          // other charts

          return datasets.map((dataset, i) => {
            const isVisible = chart.isDatasetVisible(i)

            return {
              text: dataset.label as string,
              fillStyle: isVisible
                ? (dataset.backgroundColor as string[])[0]
                : theme.palette.action.disabled,
              strokeStyle: 'transparent',
              lineWidth: 0,
              borderRadius: boxSize / 2,
              fontColor: isVisible
                ? theme.palette.text.secondary
                : theme.palette.text.disabled
            }
          })
        }
      }
    },
    onClick: function (
      event: ChartEvent,
      legendItem: LegendItem,
      legend: LegendElement<ChartType>
    ) {
      // pie chart
      if (type === 'pie') {
        const data =
          legend.legendItems?.map((data) => {
            return data.text
          }) ?? []
        const index = data.indexOf(legendItem.text)

        legend.chart.toggleDataVisibility(index)
        legend.chart.update()
      } else {
        // other charts
        const datasets =
          legend.legendItems?.map((dataset) => {
            return dataset.text
          }) ?? []
        const index = datasets.indexOf(legendItem.text)

        if (legend.chart.isDatasetVisible(index) === true) {
          legend.chart.hide(index)
        } else {
          legend.chart.show(index)
        }
      }
    },
    onHover: (event) => {
      const element = event.native?.target as HTMLElement

      element.style.cursor = 'pointer'
    }
  }
}

/*-- borders --*/
const BORDER_WIDTH = 3

export const getBorderWidth = (scaleFactor: number) => {
  return BORDER_WIDTH * scaleFactor
}

export const getBorderDash = (scaleFactor: number) => {
  return [getBorderWidth(scaleFactor), getBorderWidth(scaleFactor) * 2]
}
