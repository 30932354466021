import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import { IDashboardExample } from 'types/GlobalDashboardExample'

import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

import Link from 'components_new/atoms/Link'
import Chip from 'components_new/atoms/Chip'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'
import Tooltip from 'components_new/atoms/Tooltip'

import ActivateDashboardExampleDialog from './dialogs/ActivateDashboardExampleDialog'

interface HeaderProps {
  dashboard: IDashboardExample
  sx?: object
}

const Header = (props: ComponentProps) => {
  const { AuthStore, dashboard, sx } = props

  const history = useHistory()

  const createdDiff = Date.now() - Date.parse(dashboard.sys.createdAt)
  const createdDayDiff = Math.round(createdDiff / (1000 * 3600 * 24))
  const showNew = createdDayDiff < 30

  const updatedDiff = Date.now() - Date.parse(dashboard.sys.updatedAt)
  const updatedDayDiff = Math.round(updatedDiff / (1000 * 3600 * 24))
  const showUpdated = updatedDayDiff < 14 && !showNew

  const [copySuccess, setCopySuccess] = useState<boolean>(false)

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(location.href)
    setCopySuccess(true)
  }

  const [activateDialogOpen, setActivateDialogOpen] = useState<boolean>(false)

  const disabledActive = Boolean(AuthStore?.user?.is_company_group)

  return (
    <>
      <Box sx={sx}>
        <Link
          disableHistory={true}
          href={'/explore'}
          search={history.location.search}
          sx={{ mb: 1 }}
        >
          Stäng
        </Link>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
            mb: 1
          }}
        >
          <Text variant="h2">{dashboard.fields.title}</Text>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              ml: 2
            }}
          >
            <Tooltip title={disabledActive ? 'Du saknar rättigheter' : ''}>
              <span>
                <Button
                  disabled={disabledActive}
                  onClick={() => setActivateDialogOpen(true)}
                  size="large"
                  startIcon={<Icon name="AddCircleOutlineOutlined" />}
                  color="brand"
                >
                  Aktivera
                </Button>
              </span>
            </Tooltip>
            <Button
              onClick={copyLinkToClipboard}
              size="large"
              startIcon={<Icon name="LinkOutlined" />}
              variant="outlined"
            >
              Kopiera länk
            </Button>
            <Button
              component="a"
              endIcon={<Icon name="OpenInNewOutlined" />}
              href={`https://portal.homepal.se/public/dashboards/${dashboard.fields.id}`}
              newTab={true}
              size="large"
              variant="outlined"
            >
              Visa i fullskärm
            </Button>
          </Box>
        </Box>
        <Box sx={{ mb: 1 }}>
          {showNew ? (
            <Chip color="primary" label="Nyhet ✨" variant="outlined" />
          ) : null}
          {showUpdated ? (
            <Chip color="primary" label="Uppdaterad 🎉" variant="outlined" />
          ) : null}
        </Box>
        <Text color="text.secondary" variant="caption">
          Skapad {new Date(dashboard.sys.createdAt).toLocaleDateString('sv')} •
          Uppdaterad{' '}
          {new Date(dashboard.sys.updatedAt).toLocaleDateString('sv')}
          {AuthStore.user && AuthStore.user.is_homepal_user ? (
            <>
              {' • '}
              <Tooltip
                title={`${dashboard.data.visits.unique} ${
                  dashboard.data.visits.unique === 1 ? 'unik' : 'unika'
                } användare`}
              >
                <Link sx={{ cursor: 'default' }}>
                  {dashboard.data.visits.total}{' '}
                  {dashboard.data.visits.total === 1 ? 'visning' : 'visningar'}
                </Link>
              </Tooltip>
            </>
          ) : null}
        </Text>
      </Box>

      {/*-- dialogs --*/}
      <ThemeProvider theme={getTheme('light')}>
        <ActivateDashboardExampleDialog
          dashboard={dashboard}
          onClose={() => setActivateDialogOpen(false)}
          open={activateDialogOpen}
        />
      </ThemeProvider>

      {/*-- snackbars --*/}
      <Portal>
        <Snackbar
          open={copySuccess}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setCopySuccess(false)
            }
          }}
          message="Kopierat till urklipp"
        />
      </Portal>
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> & HeaderProps

export default connector(Header)
