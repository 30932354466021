/**
 * @param date needs to be an ISO-string of a date,
 * e.g. '2020-01-01T00:00:00.000Z' | '2020-01-01' |
 * '2020-01' | '2020'.
 *
 * @returns a date as a string on the format '1 jan. 2020'
 * | 'Jan. 2020 | '2020' or null if input is nullish.
 */

export const getParsedDate = (date: string | null | undefined): string => {
  if (date) {
    const dateObject = new Date(date)

    const string = dateObject.toLocaleString('sv', {
      day: date.length >= 10 ? 'numeric' : undefined,
      month: date.length >= 7 ? 'short' : undefined,
      year: date.length >= 4 ? 'numeric' : undefined
    })

    const firstLetter = string.charAt(0)
    const remainingLetters = string.substring(1)

    return firstLetter.toUpperCase() + remainingLetters
  }

  return ''
}

/**
 * @param fromDate and @param toDate needs to be an ISO-string of a date,
 * e.g. '2020-01-01T00:00:00.000Z' | '2020-01-01' | '2020-01' | '2020'.
 *
 * Uses @function getParsedDate and @returns a string on the format:
 * '1 jan. 2020 - 31 jan. 2020' if both inputs are set,
 * '1 jan. 2020' if only fromDate is set,
 * '1 jan. 2020' if fromDate is equal to toDate,
 * or null if both inputs are nullish.
 */

export const getParsedDateSpan = (
  fromDate: string | null | undefined,
  toDate: string | null | undefined
) => {
  if ((fromDate && !toDate) || fromDate === toDate) {
    return getParsedDate(fromDate)
  }
  if (fromDate && toDate) {
    return getParsedDate(fromDate) + ' - ' + getParsedDate(toDate)
  }

  return null
}

/**
 * Parses values that are NOT returned as a ISO-string date.
 * Includes a type @param dateType that manages what type of date like label
 * that should be returned. I.e. month, weekdays.
 */
export const getParsedPartOfDate = (
  label: number | string | null,
  dateType: string
): string => {
  let splittedLabel

  if (label != null) {
    switch (dateType) {
    case 'month':
      return MONTH_VALUE_TRANSLATOR[label as number]
    case 'weekday':
      return WEEKDAY_VALUE_TRANSLATOR[label as number]
    case 'year_quarter':
      splittedLabel = (label as string).split('-')

      return `${QUARTER_VALUE_TRANSLATOR[splittedLabel[1]]} ${
        splittedLabel[0]
      }`
    case 'year_week':
      splittedLabel = (label as string).split('-')

      return `v. ${parseWeek(splittedLabel[1])} ${splittedLabel[0]}`
    default:
      return label ? label.toString() : ''
    }
  }

  return ''
}

const MONTH_VALUE_TRANSLATOR: { [key: number]: string } = {
  1: 'Januari',
  2: 'Februari',
  3: 'Mars',
  4: 'April',
  5: 'Maj',
  6: 'Juni',
  7: 'Juli',
  8: 'Augusti',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'December'
}

const WEEKDAY_VALUE_TRANSLATOR: { [key: number]: string } = {
  0: 'Måndag',
  1: 'Tisdag',
  2: 'Onsdag',
  3: 'Torsdag',
  4: 'Fredag',
  5: 'Lördag',
  6: 'Söndag'
}

const QUARTER_VALUE_TRANSLATOR: { [key: string]: string } = {
  '1': 'Kv. 1',
  '2': 'Kv. 2',
  '3': 'Kv. 3',
  '4': 'Kv. 4'
}

const parseWeek = (week: string) => {
  if (week.charAt(0) === '0') {
    return week.slice(1)
  }

  return week
}

export const parseDatetime = (datetime: string) => {
  const date = getParsedDate(datetime)
  let time = datetime.split('T')[1]

  time = time.split('.')[0]

  return `${date} kl ${time}`
}
